import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";

const state = {
  loadingStatus: {
    setMSOPriority: false,
    setPriority: false,
  },
  filterButtons: [],
  selectedFilter: undefined,
  initialFilter:  {
    // isActive: true,
    // isDeleted: false,
  },
  sort: {
    sortPriority: -1,
  },
  pagination: { skip: 0, limit: 20 },
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
  updateFilterButtons: (state, payload) => {
    state.filterButtons = payload;
  },
};

const getters = {
  loadingStatus: (state) => state.loadingStatus,
  sort: (state) => state.sort,
  selectedFilter: (state) => state.selectedFilter,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
  filterButtons: (state) => state.filterButtons,
};

const actions = {
  setMSOProductPriority: ({ commit, state, dispatch }, payload) => {
    commit("setLoadingStatus", { api: "setMSOPriority", status: true });
    HttpService.post(
      `${Apis.backendBaseUrl}${Apis.menu.setMSOProductPriority}`,
      {
        msoProducts: payload.items,
        sortPriority: payload.sortPriority,
        resetPriority: payload.resetPriority ?? false,
      },
      function (response, error) {
        commit("setLoadingStatus", { api: "setMSOPriority", status: false });
        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            {
              text: error.message,
              type: "error",
              errorType: error.errorCode == 1004 ? "no-workstation" : undefined,
            },
            { root: true }
          );
        }
      }
    );
  },
  setMSOSupplierPriority: ({ commit, state, dispatch }, payload) => {
    commit("setLoadingStatus", { api: "setMSOPriority", status: true });
    HttpService.post(
      `${Apis.backendBaseUrl}${Apis.menu.setMSOSupplierPriority}`,
      {
        msoSuppliers: payload.items,
        sortPriority: payload.sortPriority,
        resetPriority: payload.resetPriority ?? false,
      },
      function (response, error) {
        commit("setLoadingStatus", { api: "setMSOPriority", status: false });
        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            {
              text: error.message,
              type: "error",
              errorType: error.errorCode == 1004 ? "no-workstation" : undefined,
            },
            { root: true }
          );
        }
      }
    );
  },
  setProductPriority: ({ commit, state, dispatch }, payload) => {
    commit("setLoadingStatus", { api: "setPriority", status: true });
    HttpService.post(
      `${Apis.backendBaseUrl}${Apis.menu.setProductPriority}`,
      {
        products: payload.items,
        sortPriority: payload.sortPriority,
        resetPriority: payload.resetPriority ?? false,
      },
      function (response, error) {
        commit("setLoadingStatus", { api: "setPriority", status: false });
        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            {
              text: error.message,
              type: "error",
              errorType: error.errorCode == 1004 ? "no-workstation" : undefined,
            },
            { root: true }
          );
        }
      }
    );
  },
  setSupplierPriority: ({ commit, state, dispatch }, payload) => {
    commit("setLoadingStatus", { api: "setPriority", status: true });
    HttpService.post(
      `${Apis.backendBaseUrl}${Apis.menu.setSupplierPriority}`,
      {
        suppliers: payload.items,
        sortPriority: payload.sortPriority,
        resetPriority: payload.resetPriority ?? false,
      },
      function (response, error) {
        commit("setLoadingStatus", { api: "setPriority", status: false });
        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            {
              text: error.message,
              type: "error",
              errorType: error.errorCode == 1004 ? "no-workstation" : undefined,
            },
            { root: true }
          );
        }
      }
    );
  },
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  updateSort: ({ commit, state }, payload) => {
    commit("updateSort", payload);
  },
  updatePagination: ({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter: ({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  },
  updateFilterButtons: ({ commit, state }, payload) => {
    commit("updateFilterButtons", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
