<template>
    <div class="stateInventory responsive-menu">
      <div class="responsive-menu__child">
        <PageHeader
          title="Inventory Forecast"
          :links="[
            { name: 'home', label: 'Home' },
            { name: 'reports', label: 'Reports' },
            { name: 'inventoryReport', label: 'Inventory' },
            {
              name: 'inventoryForecast',
              label: 'Inventory Forecast',
            },
          ]"
          @export="exportToCsv"
          :disableExport="!reportData"
        />
        <br class="no-print" />
        <ReportFilter
          class="no-print"
          :filterByItemType="true"
          @submit-filter="performGetReport"
          :isLoading="inventoryTransactions"
        />
        <div class="report-container">
          <!-- This could be a component -->
          <div class="report-body" v-if="reportData">
            <table id="table-report">
              <thead>
                <tr class="report-body__row report-body__row--header"></tr>
                <tr><td colspan="3">Inventory Forecast</td></tr>
                <tr><td colspan="3">{{ reportData.total.datePeriod }}</td></tr>
                <tr><td> </td></tr><tr><td> </td></tr>

                <!-- <tr>
                    <th colspan="4" align="left">Period:</th>
                    <th colspan="2" align="center">Under 30</th>
                    <th colspan="2" align="center">31 - 45 Days</th>
                    <th colspan="2" align="center">46 - 60 Days</th>
                    <th colspan="2" align="center">61 - 90 Days</th>
                </tr> -->

                <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>
                <tr>
                    <th align="left">supplier</th>
                    <th align="left">group</th>
                    <th align="left">name</th>
                    <th align="right">on hand</th>
                    <th align="right">0-30 days quantity</th>
                    <th align="right">0-30 days cost</th>
                    <th align="right">31-45 days quantity</th>
                    <th align="right">31-45 days cost</th>
                    <th align="right">46-60 days quantity</th>
                    <th align="right">46-60 days cost</th>
                    <th align="right">61-90 days quantity</th>
                    <th align="right">61-90 days cost</th>
                </tr>
                <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>
              </thead>

              <tbody>
                <tr class="report-body__row"></tr>
                <tr v-for="(record, index) in reportData.records" :key="index">
                    <td>{{ record.supplier }}</td>
                    <td>{{ record.group }}</td>
                    <td>{{ record.name }}</td>
                    <td align="right"> {{ record.quantityOnHand }}</td>
                    <td align="right"> {{ record.days_0_30 }}</td>
                    <td align="right"> {{ Utilities.formatPrice(record.days_0_30_Cost) }}</td>
                    <td align="right"> {{ record.days_30_45 }}</td>
                    <td align="right"> {{ Utilities.formatPrice(record.days_30_45_Cost) }}</td>
                    <td align="right"> {{ record.days_45_60 }}</td>
                    <td align="right"> {{ Utilities.formatPrice(record.days_45_60_Cost) }}</td>
                    <td align="right"> {{ record.days_60_90 }}</td>
                    <td align="right"> {{ Utilities.formatPrice(record.days_60_90_Cost) }}</td>
                </tr>
              </tbody>
              <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>
              <tr>
                <td colspan="3">Totals</td>
                <td align="right"> {{ reportData.total.quantityOnHand }}</td>
                <td align="right"> {{ reportData.total.days_0_30 }}</td>
                <td align="right"> {{ Utilities.formatPrice(reportData.total.days_0_30_Cost) }}</td>
                <td align="right"> {{ reportData.total.days_30_45 }}</td>
                <td align="right"> {{ Utilities.formatPrice(reportData.total.days_30_45_Cost) }}</td>
                <td align="right"> {{ reportData.total.days_45_60 }}</td>
                <td align="right"> {{ Utilities.formatPrice(reportData.total.days_45_60_Cost) }}</td>
                <td align="right"> {{ reportData.total.days_60_90 }}</td>
                <td align="right"> {{ Utilities.formatPrice(reportData.total.days_60_90_Cost) }}</td>
              </tr>
              <tr class="skip-export"></tr>
              <tr class="skip-export">
                <td colspan="100%"><hr class="line1" /></td>
              </tr>

            </table>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import PageHeader from "@/components/Header/Header.vue";
  import ReportHelper from "@/lib/report/index.js";
  import { mapActions, mapGetters } from "vuex";
  import ReportFilter from "@/components/ReportFilter/ReportFilter";
  import Utilities from "@/lib/Utilities";

  export default {
    data() {
      return {
        reportData: undefined,
        Utilities,
        reportType: undefined,
        selectedPeriod: undefined,
        selectedReportFor: undefined,
        selectedReportSaleType: undefined,
      };
    },
    components: {
      PageHeader,
      ReportFilter,
    },
    created: function () {
      this.setPageTitle("Inventory Forecast");
    },
    mounted: function () {
      const today = new Date();
      this.selectedPeriod = {
        from: new Date(new Date().setDate(today.getDate() - 7)),
        to: today,
      };
    },
    computed: {
      ...mapGetters({
        loadingStatus: "reports/loadingStatus",
        inventoryTransactions: "reports/inventoryTransactions",
      }),
    },
    methods: {
      ...mapActions({
        getSalesTransactions: "reports/getInventoryTransactions",
      }),
      performGetReport(filter) {
        let reportType = "inventoryForecast";
        this.getSalesTransactions({
          reportType,
          ...filter,
          positiveCallback: (data) => {
            this.reportData = data;
          },
        });
      },
      exportToCsv() {
        var table = document.getElementById("table-report");
        ReportHelper.exportToExcel(table, "inventoryForecast");
      },
    },
  };
  </script>

  <style lang="scss">
  @import "./_inventoryForecast.scss";