<template>
  <div class="customer-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'customers', label: 'Customers' },
          {
            name: 'customerEditor',
            label: selectedCustomer.name,
            query: $route.query,
          },
        ]"
        :isEdit="isEdit"
        :isCollapsed="isCollapsed"
        :entity="entity"
        @toggle-edit="() => toggleEdit()"
        @toggle-sections="forceToggle"
        @show-search-modal="() => (showSearchModal = !showSearchModal)"
        @menu-option-click="menuOptionClick"
        :selectedEntity="selectedCustomer"
      >
        <BaseBtn
          data-cy="cannabis-limit-button"
          label="Cannabis Limit"
          iconGroup="fa"
          iconName="cannabis"
          @click="performCheckCannabisLimit"
          v-if="
            isMedical &&
            selectedCustomer._id != 'NEW' &&
            (selectedCustomer.isPatient ||
              selectedCustomer.isMinor ||
              selectedCustomer.isOapp)
          "
          :isLoading="stateLoadingStatus.checkCannabisLimit"
          :disabled="stateLoadingStatus.checkCannabisLimit"
        />
        <BaseBtn
          data-cy="check-in-button"
          label="Check In"
          iconGroup="fa"
          iconName="user-check"
          @click="checkIn"
          v-if="showCheckInButton"
        />
      </PageHeader>
      <!-- Main Customer Information  -->
      <div class="section-base">
        <div class="padded elevated-0 bordered">
          <div class="two-columns-layout two-columns-layout--responsive">
            <div v-for="inputs in getCustomerInfoInputs" :key="inputs.id">
              <div
                class="grid-two-column two-columns-layout--responsive-horizontal-space-10"
              >
                <div
                  v-for="input in inputs.inputs"
                  :key="input.id"
                  :class="'input-text' + ' ' + input.class"
                >
                  <Input
                    :model="selectedCustomer[input.id]"
                    @updateModel="selectedCustomer[input.id] = $event"
                    :inputData="input"
                    :isEdit="isEdit"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex__gap padded--10">
            <div>
              <div class="input-text__label">Marketing Opt In</div>
              <CheckBox
                label="Authorize SMS and Email Marketing"
                @click="
                  () => {
                    if (isEdit && canEditMarketingOptIn)
                      selectedCustomer.isMarketingOptIn =
                        !selectedCustomer.isMarketingOptIn;
                  }
                "
                :disabled="!canEditMarketingOptIn"
                :model="selectedCustomer.isMarketingOptIn"
                :toggleVariable="selectedCustomer.isMarketingOptIn"
              />
              <div v-if="currentStore.loyaltySystem === 'springbig' && selectedCustomer && selectedCustomer.loyaltyInfo">
                <div v-if="selectedCustomer.isMarketingOptIn === false">Customer SMS and Email settings must be set in SpringBig's dashboard.</div>
                <div v-if="selectedCustomer.loyaltyInfo.isOptedOut">Customer has opted out of SpringBig Loyalty - must opt in through SpringBig's dashboard.</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <EditorSection
        v-if="id !== 'NEW' && id !== undefined"
        title="Analytics"
        :bus="bus"
        :defaultIsOpen="true"
      >
        <CustomerAnalytics
          :topProductsChartData="topProductsChartData"
          :dashboardLoadingStatus="loadingStatus"
          :spendingInformation="spendingInformation"
          :calendarHeatmapChartData="calendarHeatmapChartData"
        />
      </EditorSection>

      <EditorSection
        title="Sale History"
        v-if="selectedCustomer._id !== 'NEW'"
        :bus="bus"
      >
        <ItemHistory :customerId="selectedCustomer._id" />
      </EditorSection>

      <EditorSection
        title="Credit Memos"
        v-if="selectedCustomer._id !== 'NEW'"
        :bus="bus"
      >
        <SearchTable
          :entity="Entities.CREDITMEMO"
          :hideFilters="true"
          :filter="preFilter"
          :sort="sort"
          :key="id"
        />
      </EditorSection>

      <!-- Address Info Begins  -->
      <EditorSection
        title="Address/License Information"
        sectionType="address"
        :bus="bus"
      >
        <div class="space-between space-between--allow-wrapping">
          <div
            v-for="input in addressInputs"
            :key="input.id"
            class="input-text"
          >
            <Input
              :model="selectedCustomer[input.id]"
              @updateModel="selectedCustomer[input.id] = $event"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
        </div>
      </EditorSection>
      <!-- Address Info Ends -->

      <!-- Verification Begins for Patient Card  -->

      <EditorSection
        v-if="isMedical"
        title="State Info"
        sectionType="verification"
        :bus="bus"
      >
        <div
          class="grid-two-column two-columns-layout--responsive-horizontal-space-10"
        >
          <div
            v-for="input in verificationInputs"
            :key="input.id"
            class="input-text"
          >
            <Input
              :model="selectedCustomer[input.id]"
              @updateModel="selectedCustomer[input.id] = $event"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
        </div>
      </EditorSection>
      <!-- Verification Ends for Patient Card  -->

      <!-- Verification Begins for Caregiver Card  -->
      <EditorSection
        v-if="isMedical"
        title="Caregiver State Info"
        sectionType="verificationCaregiver"
        :bus="bus"
      >
        <div class="space-between space-between--allow-wrapping">
          <div
            v-for="input in verificationCaregiverInputs"
            :key="input.id"
            class="input-text"
          >
            <Input
              :model="selectedCustomer[input.id]"
              @updateModel="selectedCustomer[input.id] = $event"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
        </div>
      </EditorSection>
      <!-- Verification Ends for Caregiver Card   -->

      <!-- Customer's Caregiver Begin  -->
      <EditorSection
        v-if="isMedical"
        title="Caregivers Assigned"
        sectionType="caregiver"
        :bus="bus"
      >
        <div
          v-for="input in addCaregiverInput"
          :key="input.id"
          class="add-caregiver-container"
        >
          <Input
            :model="selectedCustomer[input.id]"
            @updateModel="addCaregiver($event)"
            :inputData="input"
            :isEdit="isEdit"
          />
        </div>
        <CaregiversList
          v-if="
            selectedCustomer.caregivers &&
            selectedCustomer.caregivers.length > 0
          "
          :isEdit="isEdit"
          @deleteCaregiver="deleteCaregiver"
          :caregivers="selectedCustomer.caregivers"
        />
      </EditorSection>
      <!-- Customer's Caregiver End  -->

      <!-- Sale Discounts & Point Tracking Begin  -->
      <EditorSection
        :title="
          currentStore.loyaltySystem === 'dispense-first'
            ? 'Sale Discounts & Point Tracking'
            : 'Sale Discounts'
        "
        sectionType="sales"
        :bus="bus"
      >
        <div
          class="space-between space-between--allow-wrapping"
          v-canAccess="managementRoles"
        >
          <div
            v-for="input in saleDiscountsInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <Input
              :model="selectedCustomer[input.id]"
              @updateModel="selectedCustomer[input.id] = $event"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
          <div
            v-show="currentStore.loyaltySystem === 'dispense-first'"
            v-for="input in dfPointInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <Input
              :model="selectedCustomer[input.id]"
              @updateModel="selectedCustomer[input.id] = $event"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
          <div
            v-show="currentStore.loyaltySystem === 'clutch'"
            v-for="input in loyaltyProgramInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <Input
              :model="getProperty(input.id)"
              @updateModel="(e) => setProperty(input.id, e)"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
        </div>
        <div v-canAccess="!managementRoles" style="margin: 0.5rem 0">
          <MessageBox level="default">
            Only managers can see or edit customers discounts
          </MessageBox>
        </div>
      </EditorSection>
      <!-- Sale Discounts & Discount Categories End  -->

      <!-- Clutch  -->
      <EditorSection
        v-if="currentStore.loyaltySystem === 'clutch'"
        title="Clutch"
        sectionType="sales"
        :bus="bus"
      >
        <div class="space-between space-between--allow-wrapping">
          <div
            v-for="input in loyaltyProgramInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <Input
              :model="getProperty(input.id)"
              @updateModel="(e) => setProperty(input.id, e)"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
        </div>
        <div class="space-between space-between--allow-wrapping padded--10">
          <LoyaltySummary
            v-if="selectedCustomer.loyaltyInfo"
            :loyaltyInfo="selectedCustomer.loyaltyInfo"
            :showCardNumber="false"
          />
          <div>
            <BaseBtn
              iconGroup="fas"
              iconName="user-tag"
              label="Loyalty Customer Lookup"
              @click="clutchLookup"
              :disabled="!isEdit"
            />
          </div>
        </div>
      </EditorSection>
      <!-- Clutch  -->

      <!-- Springbig  -->
      <EditorSection
        v-if="currentStore.loyaltySystem === 'springbig'"
        title="Springbig"
        :bus="bus"
      >
        <div class="flex flex--gap">
          <div
            v-for="input in loyaltyProgramInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <Input
              :model="getProperty(input.id)"
              @updateModel="(e) => setProperty(input.id, e)"
              :inputData="input"
              :isEdit="isEdit && input.id !== 'loyaltyInfo.cardNumber'"
            />
          </div>
          <LoyaltySummary
            v-if="selectedCustomer.loyaltyInfo"
            :loyaltyInfo="selectedCustomer.loyaltyInfo"
            :showCardNumber="false"
            :refreshLoyalty="refreshLoyalty"
          />
        </div>
        <div class="spring-big-enroll">
            <p>If an error occurs while loading a customer's point balance, re-enroll.</p>
            <BaseBtn
              iconGroup="fas"
              iconName="user-tag"
              label="Re-Enroll Customer"
              @click="relinkLoyalty()"
              :isLoading="loyaltyLoadingStatus.enroll"
              :disabled="!isEdit"
              />
        </div>
      </EditorSection>

      <!-- Alpine  -->
      <EditorSection
        v-if="currentStore.loyaltySystem === 'alpine'"
        title="Alpine IQ"
        :bus="bus"
      >
        <div class="flex flex--gap">
          <div
            v-for="input in loyaltyProgramInputs"
            :key="input.id"
            :class="'input-text' + ' ' + input.class"
          >
            <Input
              :model="getProperty(input.id)"
              @updateModel="(e) => setProperty(input.id, e)"
              :inputData="input"
              :isEdit="isEdit && input.id !== 'loyaltyInfo.cardNumber'"
            />
          </div>
          <LoyaltySummary
            v-if="selectedCustomer.loyaltyInfo"
            :loyaltyInfo="selectedCustomer.loyaltyInfo"
            :showCardNumber="false"
            :phone="selectedCustomer.phone"
            :email="selectedCustomer.email"
          />
        </div>
      </EditorSection>
      <!-- Clutch  -->

      <!-- Notes Begin  -->
      <EditorSection title="Notes" sectionType="notes" :bus="bus">
        <div class="editor-section-button-row margin-bottom-md">
          <inline-button
            label="Add"
            type="plain-primary responsive-column form-height wide"
            @click="addNote"
            :disabled="!isEdit"
          />
        </div>
        <div
          v-for="(note, i) in selectedCustomer.noteList"
          :key="note._id"
          class="two-columns-layout--border-bottom"
        >
          <CustomerNote
            @delete-note="() => deleteNote(i)"
            :isEdit="isEdit"
            :note="note"
          />
          <About :embedded="true" v-if="note._id !== 'NEW'" :entity="note" />
        </div>
      </EditorSection>
      <!-- Notes End  -->

      <!-- Notifications Begin  -->
      <EditorSection
        title="Notifications"
        sectionType="notifications"
        :bus="bus"
      >
        <div v-for="notification in notificationTypes" :key="notification">
          <h3 class="field">{{ notification }}</h3>
          <div class="flex">
            <div
              v-for="input in notificationInputs"
              :key="input.id"
              class="input-text margined--right"
            >
              <Input
                :model="selectedCustomer[input.id]"
                @updateModel="selectedCustomer[input.id] = $event"
                :inputData="input"
                :isEdit="isEdit"
              />
            </div>
          </div>
        </div>
      </EditorSection>
      <!-- Notifications Program End  -->

      <!-- Other Begin  -->
      <EditorSection
        title="Other Information"
        sectionType="referrals"
        :bus="bus"
      >
        <div class="space-between space-between--allow-wrapping">
          <div
            v-for="input in referralInputs"
            :key="input.id"
            class="input-text"
          >
            <Input
              :model="selectedCustomer[input.id]"
              @updateModel="selectedCustomer[input.id] = $event"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
        </div>
      </EditorSection>

      <EditorSection
        :title="`About ${selectedCustomer.name || ''}`"
        sectionType="about"
        v-if="selectedCustomer._id !== 'NEW'"
        :bus="bus"
      >
        <About :entity="selectedCustomer" />
      </EditorSection>
    </div>
    <JournalEntries
      v-if="showJournalEntries"
      :showSearchModal="showJournalEntries"
      @toggle-modal="() => (showJournalEntries = !showJournalEntries)"
      :type="journalEntryType"
      :filter="filterJournalEntries"
      :sort="sortJournalEntries"
      :hideFilter="true"
    />
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
    <BaseModal
      v-if="isCannabisLimitPopUpOpen"
      @toggle-modal="isCannabisLimitPopUpOpen = false"
      title="Patient Cannabis Limit"
      :autoWidth="true"
    >
      <div
        style="
          min-height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        The patient cannabis limit is: {{ cannabisLimitResult.card_limit }}g
      </div>
    </BaseModal>
    <BaseModal
      v-if="isCheckInPopUpOpen"
      @toggle-modal="isCheckInPopUpOpen = false"
      title="Check In Customer As"
    >
      <div
        style="
          min-height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
        "
      >
        <BaseBtn
          data-cy="check-in-as-medical-button"
          label="Medical Check In"
          iconGroup="fa"
          iconName="briefcase-medical"
          @click="checkInAs('medical')"
          v-if="showMedicalCheckIn"
        />
        <BaseBtn
          data-cy="check-in-as-recreational-button"
          label="Recreational Check In"
          iconGroup="fa"
          iconName="grin-beam"
          @click="checkInAs('recreational')"
          v-if="showRecreationalCheckIn"
        />
      </div>
    </BaseModal>
    <BaseModal
      v-if="showLoyaltyLookupPopUp"
      @toggle-modal="showLoyaltyLookupPopUp = false"
      title="Clutch Lookup"
    >
      <div class="customer-check-in__padded-container">
        <LoyaltyLookup
          :selectCustomer="selectLoyaltyCustomer"
          :name="selectedCustomer.name"
          :lastName="selectedCustomer.lastName"
          :email="selectedCustomer.email"
          :phone="selectedCustomer.phone"
        />
      </div>
    </BaseModal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import LoyaltyLookup from "@/components/LoyaltyLookup/LoyaltyLookup.vue";

import Utilities from "@/lib/Utilities";
import Entities from "@/resources/Entities";
import { setNewEntity } from "@/utils/initialize-page";
import Constants from "@/resources/Constants";
import router from "@/router";

import JournalEntries from "@/components/JournalEntries/JournalEntries.vue";
import EditorSection from "@/components/EditorSection.vue";
import PageHeader from "@/components/PageHeader.vue";
import About from "@/components/About/About.vue";
import Input from "@/components/Input/Input.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";
import BaseModal from "@/components/Modals/BaseModal/BaseModal.vue";
import CustomerNote from "@/components/CustomerNote.vue";
import SearchTable from "@/components/SearchTable";
import CaregiversList from "./components/CaregiversList.vue";
import ItemHistory from "./components/ItemHistory/ItemHistory.vue";
import LoyaltySummary from "@/components/LoyaltySummary/LoyaltySummary.vue";
import CustomerAnalytics from "@/components/CustomerAnalytics/CustomerAnalytics.vue";
import {
  getDefaultTopProductsChartData,
  populateTopProductsChartData,
} from "@/views/dashboards/SalesOverview/helpers/topProducts.helper";
import {
  getDefaultCalendarHeatmapChartData,
  populateCalendarHeatmapChartData,
} from "@/views/dashboards/SalesOverview/helpers/calendarHeatmap.helper";
import MessageBox from "@/components/MessageBox/MessageBox.vue";
import { prefillCustomerFieldsFromParams } from "@/views/Customer/helpers/prefill-customer-fields-from-params";
import CheckBox from "@/components/CheckBox.vue";

import {
  customerInfoInputs,
  verificationInputs,
  verificationCaregiverInputs,
  saleDiscountsInputs,
  customerCaregiverInputs,
  notesInputs,
  notificationInputs,
  notificationTypes,
  referralInputs,
  addressInputs,
  columnHeaders,
  filterCriteria,
  pageHeader,
  addCaregiverInput,
  dfPointInputs,
  loyaltyProgramInputs,
} from "./inputs";
import { managementRoles } from "@/constants/roles";
export default {
  name: "customerEditor",
  data() {
    return {
      Constants,
      moment,
      Utilities,
      customerInfoInputs,
      verificationInputs,
      verificationCaregiverInputs,
      saleDiscountsInputs,
      customerCaregiverInputs,
      loyaltyProgramInputs,
      notesInputs,
      notificationInputs,
      notificationTypes,
      referralInputs,
      addressInputs,
      caregivers: [],
      isEdit: false,
      selectedCaregiver: undefined,
      customers: [],
      customersResults: [],
      bus: new Vue(),
      isCollapsed: true,
      showSearchModal: false,
      columnHeaders,
      filterCriteria,
      entity: Entities.CUSTOMER,
      pageHeader,
      showJournalEntries: false,
      journalEntryType: "customer",
      isCannabisLimitPopUpOpen: false,
      cannabisLimitResult: {},
      isCheckInPopUpOpen: false,
      Entities,
      addCaregiverInput,
      dfPointInputs,
      showLoyaltyLookupPopUp: false,
      topProductsChartData: undefined,
      spendingInformation: {},
      calendarHeatmapChartData: undefined,
      managementRoles,
      refreshLoyalty: 0,
    };
  },
  components: {
    PageHeader,
    About,
    EditorSection,
    Input,
    JournalEntries,
    BaseBtn,
    BaseModal,
    CustomerNote,
    SearchTable,
    CaregiversList,
    ItemHistory,
    LoyaltyLookup,
    LoyaltySummary,
    CustomerAnalytics,
    MessageBox,
    CheckBox,
  },
  mounted: async function () {
    this.setPageTitle("Customer");
    this.getCustomers({
      positiveCallback: (customers) => {
        this.customers = customers;
        this.caregivers = customers;
      },
    });
    if (this.$route.query.id) {
      this.getCustomerById({ _id: this.$route.query.id });
    } else {
      // new element
      setNewEntity(this.entity);
      this.isEdit = true;
    }

    this.fetchCustomerData(this.$route.query.id);

    this.topProductsChartData = getDefaultTopProductsChartData(this.theme);

    this.calendarHeatmapChartData = getDefaultCalendarHeatmapChartData(
      this.theme
    );

    prefillCustomerFieldsFromParams(this.selectedCustomer, this.$route.query);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.bus.$emit("forceToggle", true);
      }
      if (this.id && this.id !== "NEW") {
        this.getCustomerById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
        this.fetchCustomerData(this.id);
      }
      if (!this.id && this.id == "NEW") {
        this.clearData();
        this.topProductsChartData = undefined;
        this.calendarHeatmapChartData = getDefaultCalendarHeatmapChartData(
          this.theme
        );
        this.spendingInformation = {};
      }
    },
    "getData.topProducts.list"(newData) {
      populateTopProductsChartData(this.topProductsChartData, newData);
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    showCheckInButton() {
      const isPatient = this.selectedCustomer.isPatient;
      const isOapp = this.selectedCustomer.isOapp;
      const isMinor = this.selectedCustomer.isMinor;
      const isCaregiver = this.selectedCustomer.isCaregiver;
      const isMedical = this.currentStore.isMedical;
      const accountType = this.selectedCustomer.accountType;
      const isRecreational = this.currentStore.isRecreational;

      if (this.selectedCustomer._id == "NEW") {
        return false;
      }

      if (isMedical && (isPatient || isMinor || isCaregiver || isOapp)) {
        return true;
      }

      if (isRecreational && accountType?.code?.includes("rec")) {
        return true;
      }

      return false;
    },
    showMedicalCheckIn() {
      const isPatient = this.selectedCustomer.isPatient;
      const isOapp = this.selectedCustomer.isOapp;
      const isMinor = this.selectedCustomer.isMinor;
      const isCaregiver = this.selectedCustomer.isCaregiver;
      const isMedical = this.currentStore.isMedical;

      if (isMedical && (isPatient || isMinor || isCaregiver || isOapp)) {
        return true;
      }

      return false;
    },
    showRecreationalCheckIn() {
      const accountType = this.selectedCustomer.accountType;
      const isRecreational = this.currentStore.isRecreational;

      if (isRecreational && accountType?.code?.includes("rec")) {
        return true;
      }

      return false;
    },
    sortJournalEntries() {
      return { createdAt: 1 };
    },
    filterJournalEntries() {
      if (this.journalEntryType == "customerPayments") {
        return {
          customer: this.id,
          origin: "P",
        };
      }
      return {
        customer: this.id,
      };
    },
    preFilterOrder() {
      return {
        customer: this.id,
        isPostedToJournal: true,
      };
    },
    preFilter() {
      return {
        customer: this.id,
      };
    },
    sort() {
      return { createdAt: 1 };
    },
    ...mapGetters({
      loadingStatus: "customerModule/loadingStatus",
      stateLoadingStatus: "stateModule/loadingStatus",
      loyaltyLoadingStatus: "loyaltyModule/loadingStatus",
      customer: "customerModule/customer",
      currentStore: "storeModule/currentStore",
      theme: "theme",
      getData: "dashboardModule/getData",
      role: "loginModule/role",
    }),
    isMedical() {
      try {
        return (
          this.selectedCustomer.accountType.code == "medical" ||
          this.selectedCustomer.accountType.code == "medical-and-recreational"
        );
      } catch (error) {
        return false;
      }
    },
    selectedCustomer: {
      get() {
        return this.prefillEntityBeforeRendering(this.customer);
      },
      set(entity) {
        return entity;
      },
    },
    canEditMarketingOptIn() {
      if (this.currentStore.loyaltySystem !== "springbig") {
        return true;
      }
      return this.selectedCustomer?.loyaltyInfo?.cardNumber === undefined;
    },
    getCustomerInfoInputs() {
      if (this.currentStore?.loyaltySystem === "springbig") {
        const phoneInput = this.customerInfoInputs[0].inputs.find(
          (i) => i.id === "phone"
        );
        phoneInput.required = true;
      }
      return this.customerInfoInputs;
    },
    isManagerRole() {
      return this.managementRoles.includes(this.role);
    },
  },
  methods: {
    ...mapActions({
      getCustomerById: "customerModule/getCustomerById",
      saveCustomer: "customerModule/saveCustomer",
      clearCustomerPoints: "customerModule/clearCustomerPoints",
      addCustomerPoints: "customerModule/addCustomerPoints",
      deleteCustomer: "customerModule/deleteCustomer",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      getCustomers: "customerModule/getCustomers",
      getAdjacentCustomer: "customerModule/getAdjacentCustomer",
      checkCannabisLimit: "stateModule/checkCannabisLimit",
      batchGenerate: "dashboardModule/batchGenerate",
      clearData: "dashboardModule/clearData",
      getCustomerSpendingInformation:
        "customerModule/getCustomerSpendingInformation",
      getCustomerOrdersByDay: "customerModule/getCustomerOrdersByDay",
      reenrollCustomer: "loyaltyModule/reenrollCustomer",
    }),
    fetchCustomerData(id) {
      if (!id || id == "NEW") {
        return;
      }

      this.batchGenerate({
        requests: [
          {
            reportType: "topProducts",
            ...{
              selectedReportFor: "store",
              customer: id,
            },
          },
        ],
      });

      this.getCustomerSpendingInformation({
        customerId: id,
        positiveCallback: (result) => {
          this.spendingInformation = result;
        },
      });

      this.getCustomerOrdersByDay({
        customerId: id,
        positiveCallback: (result) => {
          this.calendarHeatmapChartData = populateCalendarHeatmapChartData(
            result,
            this.theme
          );
        },
      });
    },
    getProperty(id) {
      return _.get(this.selectedCustomer, id);
    },
    setProperty(id, event) {
      _.set(this.selectedCustomer, id, event);
    },
    performCheckCannabisLimit() {
      this.checkCannabisLimit({
        customerId: this.selectedCustomer._id,
        positiveCallback: (result) => {
          this.cannabisLimitResult = result;
          this.isCannabisLimitPopUpOpen = true;
        },
      });
    },
    relinkLoyalty() {
      const payload = {_id: this.selectedCustomer._id}
      this.reenrollCustomer({
        payload,
        positiveCallback: (result) => {
          this.refreshLoyalty += 1;
        },
      });
    },
    checkIn() {
      if (
        this.selectedCustomer.caregivers &&
        this.selectedCustomer.caregivers.length > 0 &&
        this.selectedCustomer.isMinor
      ) {
        return this.setMessage({
          text: "This patient must be checked in through their caregiver.",
          type: "error",
        });
      }
      if (this.selectedCustomer.accountType.code == "recreational") {
        this.checkInAs("recreational");
      }
      if (this.selectedCustomer.accountType.code == "medical") {
        this.checkInAs("medical");
      }
      if (
        this.selectedCustomer.accountType.code == "medical-and-recreational"
      ) {
        if (this.currentStore.isMedical && this.currentStore.isRecreational) {
          this.isCheckInPopUpOpen = true;
        } else if (this.currentStore.isMedical) {
          this.checkInAs("medical");
        } else if (this.currentStore.isRecreational) {
          this.checkInAs("recreational");
        }
      }
    },
    checkInAs(checkInType) {
      window
        .open(
          `/customerCheckIn?customerId=${this.selectedCustomer._id}&checkInType=${checkInType}`
        )
        .focus();
    },
    performSave(options = {}) {
      if (this.loadingStatus.saveCustomer) {
        return;
      }

      this.selectedCustomer.verifyIt = options.verifyIt;
      this.selectedCustomer.terminateIt = options.terminateIt;

      if (
        !this.validateEntityBeforeSaving(this.selectedCustomer, this.stores) &&
        !options.terminateIt
      ) {
        return;
      }

      const customerToSave = this.normalizeEntityBeforeSaving(
        this.selectedCustomer,
        this.stores
      );

      this.saveCustomer({
        customer: customerToSave,
        positiveCallback: (customer) => {
          this.setPageTitle(
            `Customer ${Utilities.composeNameAndLastName(customer)}`
          );

          if (this.selectedCustomer._id === "NEW") {
            router.push({
              name: "customerEditor",
              query: { id: customer._id },
            });
          }
          this.getCaregivers({
            positiveCallback: (caregivers) => {
              this.caregivers = caregivers;
            },
          });

          this.selectedCustomer = this.prefillEntityBeforeRendering(customer);
          this.isEdit = false;
        },
      });
    },
    prefillEntityBeforeRendering(customer) {
      if (customer.hasOnlineStoreCredentials) {
        customer.password = "*********";
      } else {
        customer.password = "";
      }
      customer.confirmedPassword = "";

      if (customer.creditHold == false) {
        customer.creditHoldDate = ""
      }

      if (customer.creditHoldDate) {
        customer.creditHoldDate = moment(customer.creditHoldDate).format(
          this.Constants.dates.defaultDateFormat
        );
      }

      if (customer.verificationExpirationDate) {
        customer.verificationExpirationDate = moment(
          customer.verificationExpirationDate
        ).format(this.Constants.dates.defaultDateFormat);
      }
      if (customer.verificationDate) {
        customer.verificationDate = moment(customer.verificationDate).format(
          this.Constants.dates.defaultDateFormat
        );
      }
      if (customer.caregiverVerificationExpirationDate) {
        customer.caregiverVerificationExpirationDate = moment(
          customer.caregiverVerificationExpirationDate
        ).format(this.Constants.dates.defaultDateFormat);
      }
      if (customer.caregiverVerificationDate) {
        customer.caregiverVerificationDate = moment(
          customer.caregiverVerificationDate
        ).format(this.Constants.dates.defaultDateFormat);
      }
      if (customer.lastOrderDate) {
        customer.lastOrderDate = moment(customer.lastOrderDate).format(
          this.Constants.dates.defaultDateFormat
        );
      }
      if (customer.dob) {
        customer.dob = moment(customer.dob).format(
          this.Constants.dates.defaultDateFormat
        );
      }
      if (customer?.noteList?.length > 0) {
        customer.noteList.forEach((n) => {
          const selectedNoteType = n.noteType;
          const noteType = _.find(
            Constants.listOfCustomerNoteTypes,
            (nt) => nt.code === selectedNoteType
          );
          if (noteType) {
            n.noteType = noteType;
          }
          if (n.followUpDate) {
            n.followUpDate = moment(n.followUpDate).format(
              this.Constants.dates.defaultDateFormat
            );
          }
        });
      }
      if (customer.accountType) {
        const accountType = _.find(
          this.Constants.listOfSaleType,
          (at) => at.code === customer.accountType
        );
        if (accountType) {
          customer.accountType = accountType;
        }
      }
      return customer;
    },
    normalizeEntityBeforeSaving(entityToNormalize, stores) {
      const entity = JSON.parse(JSON.stringify(entityToNormalize));

      if (entity.creditHoldDate === "") {
        delete entity.creditHoldDate;
      }

      if (entity.verificationExpirationDate === "") {
        delete entity.verificationExpirationDate;
      }
      if (entity.verificationDate === "") {
        delete entity.verificationDate;
      }
      if (entity.caregiverVerificationExpirationDate === "") {
        delete entity.caregiverVerificationExpirationDate;
      }
      if (entity.caregiverVerificationDate === "") {
        delete entity.caregiverVerificationDate;
      }
      if (entity.password === "*********") {
        delete entity.password;
      }
      if (entity.accountType) {
        entity.accountType = entity.accountType.code;
      }
      if (entity.licenseExpirationMonth && entity.licenseExpirationMonth.code) {
        entity.licenseExpirationMonth = entity.licenseExpirationMonth.code;
      }
      if (entity.licenseExpirationYear && entity.licenseExpirationYear.code) {
        entity.licenseExpirationYear = entity.licenseExpirationYear.code;
      }

      if (this.organizationRole) {
        entity.roles = [this.organizationRole.code];
      }

      if (entity.store === undefined) {
        entity.store = _.find(stores, (s) => s.isAccessGranted);
      }

      delete entity.verifiedBy;
      delete entity.caregiverVerifiedBy;
      delete entity.organization;
      delete entity.createdBy;
      delete entity.updatedBy;
      delete entity.referredBy;
      delete entity.creditHoldBy;

      if (entity.noteList.length > 0) {
        entity.noteList.map(
          (n) =>
            (n.noteType =
              n.noteType && n.noteType.code ? n.noteType.code : undefined)
        );
      }

      return entity;
    },
    validateEntityBeforeSaving(entityToValidate) {
      var errorMessage = "";
      entityToValidate.noteList.forEach((n) => {
        if (n.noteType === undefined) {
          errorMessage += "Note type is mandatory.";
        }
      });
      if (errorMessage !== "") {
        this.setMessage({
          text: errorMessage,
          type: "error",
        });
        return false;
      }

      return true;
    },
    addNote() {
      this.selectedCustomer.noteList.unshift({
        noteType: undefined,
        hours: 0,
        note: "",
        isFollowedUp: false,
        followUpDate: "",
        _id: "NEW",
      });
    },
    deleteNote(i) {
      this.selectedCustomer.noteList.splice(i, 1);
    },
    addCaregiver(selectedCaregiver) {
      console.log({ selectedCaregiver });
      if (!selectedCaregiver) {
        this.setMessage({
          text: "Select a caregiver.",
          type: "error",
        });
        return;
      }
      const currentCaregivers = this.selectedCustomer.caregivers;
      const found = currentCaregivers.find(
        (c) => c._id == selectedCaregiver._id
      );
      if (found) return;
      this.selectedCustomer.caregivers.unshift(selectedCaregiver);
    },
    deleteCaregiver(i) {
      this.selectedCustomer.caregivers.splice(i, 1);
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedCustomer._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedCustomer._id === "NEW") {
        this.isEdit = true;
      }
    },
    menuOptionClick(entity, key) {
      if (key === "journal-entries-all" || key === "journal-entries-payments") {
        this.journalEntryType =
          key === "journal-entries-all" ? "customer" : "customerPayments";
        this.showJournalEntries = !this.showJournalEntries;
      }
      if (key == "customer-clear-points") {
        if (!this.isManagerRole)
        {
          this.setMessage({
            text: "Only managers may clear points.",
            type: "error",
          });
          return;
        }
        if (this.selectedCustomer.isPointTrackingActive) {
          this.setMessage({
            text: "Please disable point tracking for this customer before clearing points.",
            type: "error",
          });
          return;
        }
        this.setMessagePopUp({
          isOpen: true,
          title: Constants.strings.warningMessageTitle,
          message: "Are you sure that you wish to clear points for this customer?",
          positiveCallback: () => {
            this.clearCustomerPoints({ customerId: this.id });
          },
        });
      }
      if (key == "customer-add-points") {
        if (!this.isManagerRole)
        {
          this.setMessage({
            text: "Only managers may add points.",
            type: "error",
          });
          return;
        }
        if (!this.selectedCustomer.isPointTrackingActive) {
          this.setMessage({
            text: "Please enable point tracking for this customer before adding points.",
            type: "error",
          });
          return;
        }

        let warningMessage = "Are you sure that you wish to add points to this customer's account?";

        const executeUpdate = function (pointUpdate) {
          this.addCustomerPoints({
            customerId: this.selectedCustomer._id,
            addPoints: parseFloat(pointUpdate.replace(/,/g, '')),
            pointsLevel1: this.selectedCustomer.pointsLevel1 });
        }.bind(this);

        const proceedWithUpdate = function () {
          this.setMessagePopUp({
            isOpen: true,
            title: "Update Customer Points?",
            message: `Current total is $${this.selectedCustomer.pointsLevel1}. Please enter amount to add:`,
            hasTextbox: true,
            placeholder: "Enter amount to add:",
            isAnswerMandatory: false,
            isAknowledge: false,
            positiveCallback: executeUpdate,
          });
          }.bind(this);

          this.setMessagePopUp({
          isOpen: true,
          title: Constants.strings.warningMessageTitle,
          message: warningMessage,
          positiveCallback: proceedWithUpdate,
          });
      }
      if (key === "reports-customer-sale-history") {
        window.open(
          `/reports/sales/salesOrderStatusCustomerId?customerId=${this.customer._id}`
        );
      }
      if (key === "reports-customer-discount-usage") {
        window.open(
          `/reports/sales/salesOrderStatusDiscountUsage?customerId=${this.customer._id}`
        );
      }
    },
    selectItemFromSearch(entity, item, nestedId) {
      if (nestedId) {
        _.set(this.selectedItem, nestedId, item);
      }
      this.selectedCaregiver = item;
      this.addCaregiver(this.selectedCaregiver);
    },
    clutchLookup() {
      this.showLoyaltyLookupPopUp = true;
    },
    selectLoyaltyCustomer(id, loyaltyCustomer, nestedId) {
      this.selectedCustomer.loyaltyInfo = {
        cardNumber: loyaltyCustomer.cardNumber,
      };
      this.showLoyaltyLookupPopUp = false;
    },
  },
  beforeDestroy() {
    this.clearData();
  },
};
</script>

<style lang="scss" scoped>
@import "./_customer.scss";
</style>
