const REPORTS = {
    SALES_SUMMARY: "salesSummary",
    SALES_SUMMARY_BREAKDOWN: 'salesSummaryBreakdown',
    SALES_AVERAGE_SOURCE: 'salesAverageSource',
    SALES_DISCOUNT_COMPS: 'salesDiscountComps',
    SALES_DISCOUNTS_SUMMARY: 'salesDiscountSummary',
    SALES_COUPON_SUMMARY: 'salesCouponSummary',
    SALES_RECAP_TRANSACTION_DETAILED: 'salesRecapTransactionsDetailed',
    SALES_RECAP_TRANSACTION_DETAILED_EMPLOYEE: 'salesRecapTransactionsDetailedEmployee',
    SALES_RECAP_TRANSACTION_SUMMARY: 'salesRecapTransactionSummary',
    SALES_RECAP_TRANSACTION_SUMMARY_EMPLOYEE: 'salesRecapTransactionSummaryEmployee',
    SALES_RECAP_ORDER_EMPLOYEE: 'salesRecapOrderEmployee',
    SALES_PROFIT_MARGIN_BY_GROUP: 'salesProfitMarginsGroup',
    SALES_PROFIT_MARGIN_BY_GROUP_AND_PRODUCT: 'salesProfitMarginsGroupProduct',
    SALES_PROFIT_MARGIN_BY_SUPPLIER: 'salesProfitMarginsSupplier',
    SALES_PROFIT_MARGIN_BY_SUPPLIER_AND_GROUP: 'salesProfitMarginsSupplierGroup',
    SALES_PROFIT_MARGIN_BY_SUPPLIER_AND_GROUP_AND_PRODUCT: 'salesProfitMarginsSupplierGroupProduct',
    SALES_PROMO_BY_SUPPLIER: 'salesPromoSupplier',
    SALES_PROMO_BY_SUPPLIER_AND_GROUP: 'salesPromoSupplierGroup',
    SALES_PROMO_BY_SUPPLIER_AND_GROUP_AND_PRODUCT: 'salesPromoSupplierGroupProduct',
    SALES_TAX_SUMMARY: 'salesTaxSummary',
    SALES_TAX_BREAKDOWN_BY_DAY: 'salesTaxBreakdownDay',
    SALES_TAX_SCHEDULE: 'salesTaxSchedule',
    SALES_ORDER_STATUS_BATCHID: 'salesOrderStatusBatchId',
    SALES_ORDER_STATUS_PRODUCTID: 'salesOrderStatusProductId',
    SALES_ORDER_STATUS_BUYER: 'salesOrderStatusBuyer',
    SALES_ORDER_STATUS_BUYER_SUMMARY: 'salesOrderStatusBuyerSummary',
    SALES_ORDER_STATUS_CUSTOMERID: 'salesOrderStatusCustomerId',
    SALES_ORDER_STATUS_DISCOUNT_USAGE: 'salesOrderStatusDiscountUsage',
    SALES_ORDER_STATUS_ARCHIVED: 'salesOrderStatusArchived',
    SALES_ORDER_STATUS_NOTPOSTED: 'salesOrderStatusNotPosted',
    SALES_ORDER_STATUS_VOIDED: 'salesOrderStatusVoided',
    SALES_ORDER_STATUS_REFUNDS: 'salesOrderStatusRefunds',
    SALES_ORDER_STATUS_STAFFING: 'salesOrderStatusStaffing',
    INVENTORY_COUNT: 'inventoryCount',
    INVENTORY_COUNT_NC: 'inventoryCountNc',
    INVENTORY_COUNT_FEATURE_OFF: 'inventoryCountFeatureOff',
    INVENTORY_DISPOSAL: 'inventoryDisposal',
    INVENTORY_DISPOSAL_SCHEDULED: 'inventoryDisposalScheduled',
    INVENTORY_SYSTEM_CHECK: 'inventorySystemCheck',
    INVENTORY_SYSTEM_CHECK_QUICK: 'inventorySystemCheckQuick',
    INVENTORY_SYSTEM_CHECK_ITEM: 'inventorySystemCheckItem',
    INVENTORY_WORKING_COUNT: 'inventoryWorkingCount',
    TAX_SYSTEM_CHECK: 'taxSystemCheck',
    INVENTORY_MOVEMENT: 'inventoryMovement',
    INVENTORY_EXPIRING: 'inventoryExpiring',
    INVENTORY_ON_HAND: 'inventoryOnHand',
    INVENTORY_ON_HAND_AS_OF: 'inventoryOnHandAsOf',
    INVENTORY_ON_HAND_GROUP_AS_OF: 'inventoryOnHandGroupAsOf',
    INVENTORY_PURCHASE: 'inventoryPurchase',
    INVENTORY_PURCHASE_GROUP: 'inventoryPurchaseGroup',
    INVENTORY_PURCHASE_SUPPLIER: 'inventoryPurchaseSupplier',
    INVENTORY_PURCHASE_SUPPLIER_GROUP: 'inventoryPurchaseSupplierGroup',
    INVENTORY_REORDER_STATUS: 'inventoryReorderStatus',
    INVENTORY_FORECAST: 'inventoryForecast',
    INVENTORY_AGING: 'inventoryAging',
    INVENTORY_BUYER_GROUP: 'inventoryBuyerGroup',
    INVENTORY_BUYER_PRODUCT: 'inventoryBuyerProduct',
    INVENTORY_BUYER_SUPPLIER_GROUP: 'inventoryBuyerSupplierGroup',
    INVENTORY_ADJUSTMENT: 'inventoryAdjustment',
    INVENTORY_LIST_GROUP: 'inventoryListGroup',
    INVENTORY_LIST_GROUP_ONLINE: 'inventoryListGroupOnline',
    INVENTORY_LIST_SUPPLIER: 'inventoryListSupplier',
    INVENTORY_LIST_SUPPLIER_AGENT: 'inventoryListSupplierAgent',
    INVENTORY_LIST_PRODUCT: 'inventoryListProduct',
    INVENTORY_LIST_SALE_PRICING: 'inventoryListSalePricing',
    INVENTORY_LIST_SPECIAL_PRICING: 'inventoryListSpecialPricing',
    INVENTORY_TRANSFER: 'inventoryTransfer',
    INVENTORY_VOID: 'inventoryVoid',
    CUSTOMER_NEW_COUNT: 'customerNewCount',
    CUSTOMER_ZIP_CODE_COUNT: 'customerZipCodeCount',
    CUSTOMER_EMAIL: 'customerEmail',
    CUSTOMER_LOYALTY_STATUS: 'customerLoyaltyStatus',
    CUSTOMER_LOYALTY_SIGN_UP: 'customerLoyaltySignUp',
    CUSTOMER_COUPON: 'customerCoupon',
    CUSTOMER_GIFT_CARD: 'customerGiftCard',
    CUSTOMER_CAN_PAY: 'customerCanPay',
    CUSTOMER_TRANSACT_FIRST: 'customerTransactFirst',
    FINANCE_ACCOUNTS_RECEIVABLE: 'financeAccountsReceivable',
    FINANCE_DEPOSIT_SLIP: 'financeDepositSlip',
    FINANCE_DEPOSIT_SLIP_GREEN_CHECK: 'financeDepositSlipGreenCheck',
    FINANCE_JOURNAL_LEDGER: 'financeJournalLedger',
    FINANCE_PURCHASE_ORDER_UPLOAD: 'financePurchaseOrderUpload',
    FINANCE_CREDIT_MEMO_UPLOAD: 'financeCreditMemoUpload',
    FINANCE_JOURNAL_LEDGER_UPLOAD: 'financeJournalLedgerUpload',
    FINANCE_JOURNAL_BATCH_CHECK: 'financeJournalBatchCheck',
    FINANCE_LIST_CHART_OF_ACCOUNT: 'financeListChartOfAccount',
    COUPON_LIST_ACTIVE: 'couponListActive',
    EMPLOYEE_LIST_ACTIVE: 'employeeListActive',
    EMPLOYEE_HOURS: 'employeeHours',
    EMPLOYEE_NOTES: 'employeeNotes',
    CASH_RECONCILIATION_BY_EMPLOYEE: 'cashReconciliationByEmployee',
    CASH_RECONCILIATION_BY_WORKSTATION: 'cashReconciliationByWorkstation',
    CASH_COUNT_BY_WORKSTATION: 'cashCountByWorkstation',
    TOP_PRODUCTS: 'topProducts',
}

export default REPORTS;