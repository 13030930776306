import * as Entities from "@/resources/Entities";
import {
  filterCriteria as msoSupplierFilterCriteria,
  searchColumnHeaders as msoSupplierColumnHeaders,
} from "../MSOSupplier/inputs";

import {
  filterCriteria as brandFilterCriteria,
  searchColumnHeaders as brandColumnHeaders,
} from "../Brand/inputs";

import {
  filterCriteria as msoGroupFilterCriteria,
  searchColumnHeaders as msoGroupColumnHeaders,
} from "../MSOGroup/inputs";
import productExtraInfo from "@/constants/productExtraInfo";

// Page Header
export const pageHeader = {
  title: "MSO Menu",
  isEditorView: true,
  cancelPath: "msoProducts",
  addPath: "msoProductEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "MSO Menu",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "msoProductEditor",
};

export const filterCriteria = [
  {
    label: "Name",
    code: "name",
    field: { type: "input", label: "name" },
    isPartial: true,
  },
  {
    label: "Sort Priority",
    code: "sortPriority",
    field: { type: "input", label: "Sort Priority" },
  },
  {
    label: "Group Name",
    code: "externalFields.msoGroup.name",
    field: { type: "input", label: "Group Name" },
    isPartial: true,
  },
  {
    label: "Supplier",
    code: "externalFields.msoSupplier.name",
    field: { type: "input", label: "Supplier" },
    isPartial: true,
  },
  {
    label: "Brand",
    code: "externalFields.brand.name",
    field: { type: "input", label: "Brand" },
    isPartial: true,
  },
  {
    label: "Online Store Name",
    code: "onlineStoreName",
    field: { type: "input", label: "Online Store Name" },
    isPartial: true,
  },
  {
    label: "MSO Product Id",
    code: "_id",
    field: { type: "input", label: "MSO Product Id" },
  },
];

export const filterCriteriaMenuMSOSupplier = [
  ...msoSupplierFilterCriteria,
  {
    label: "Sort Priority",
    code: "sortPriority",
    field: { type: "input", label: "Sort Priority" },
  },
];

export const columnHeaders = [
  {
    id: "checked",
    type: "checkbox",
    action: "selectItem",
    size: "small",
    title: "Select",
  },
  // {
  //   id: "open",
  //   type: "button",
  //   action: "navigateToEntity",
  //   iconName: "folder-open",
  //   ariaLabel: "Open",
  // },
  {
    title: "Sort Priority",
    id: "sortPriority",
  },
  {
    title: "Active",
    id: "isActive",
    type: "checkbox",
  },
  {
    title: "Name",
    id: "name",
    size: "large",
  },
  {
    title: "MSO Supplier",
    id: "externalFields.msoSupplier.name",
  },
  {
    title: "MSO Group",
    id: "externalFields.msoGroup.name",
    size: "large",
  },
  {
    title: "Grams",
    id: "cannabisGramsUnit",
  },
  {
    title: "Brand Name",
    id: "brand.name",
    size: "large",
  },
  {
    title: "Online Store Name",
    id: "onlineStoreName",
    size: "large",
  },
  {
    title: "Id",
    id: "_id",
  },
];

export const msoMenuSupplierColumnHeaders = [
  {
    id: "checked",
    type: "checkbox",
    action: "selectItem",
    size: "small",
    title: "Select",
  },
  {
    title: "Sort Priority",
    id: "sortPriority",
  },
  ...msoSupplierColumnHeaders.slice(1),
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Active",
    id: "isActive",
    type: "checkbox",
  },
  {
    title: "Archived",
    id: "isArchived",
    type: "checkbox",
  },
  {
    title: "Name",
    id: "name",
  },
  {
    title: "MSO Supplier",
    id: "externalFields.msoSupplier.name",
  },
  {
    title: "MSO Group",
    id: "externalFields.msoGroup.name",
  },
  {
    title: "Grams",
    id: "cannabisGramsUnit",
  },
  {
    title: "Brand Name",
    id: "brandName",
  },
  {
    title: "Online Store Name",
    id: "onlineStoreName",
  },
  {
    title: "Id",
    id: "_id",
  },
];

export const msoProductInfoInputs = [
  {
    id: "msoSupplier",
    placeholder: "Select MSO Supplier",
    type: "search",
    title: "MSO Supplier",
    label: "name",
    entity: Entities.MSOSUPPLIER,
    columnHeaders: msoSupplierColumnHeaders,
    filterCriteria: msoSupplierFilterCriteria,
    filter: {
      isActive: true,
      $and: [{ isArchived: false }, { isDeleted: false }],
    },
  },
  {
    id: "msoGroup",
    placeholder: "Select MSO Group",
    type: "search",
    title: "MSO Group",
    label: "name",
    entity: Entities.MSOGROUP,
    columnHeaders: msoGroupColumnHeaders,
    filterCriteria: msoGroupFilterCriteria,
    filter: {
      isActive: true,
      $and: [{ isArchived: false }, { isDeleted: false }],
    },
  },
  {
    id: "name",
    placeholder: "Product Name",
    type: "input",
    title: "Product Name",
  },
  {
    id: "cannabisGramsUnit",
    placeholder: "Cannabis Grams/Unit",
    type: "number",
    precision: 3,
    title: "Cannabis Grams/Unit",
  },
  {
    id: "brand",
    placeholder: "Select Brand",
    type: "search",
    title: "Brand",
    label: "name",
    entity: Entities.BRAND,
    isClearable: true,
    columnHeaders: brandColumnHeaders,
    filterCriteria: brandFilterCriteria,
    filter: {
      $and: [{ isArchived: false }],
    },
  },
  {
    id: "onlineStoreName",
    placeholder: "Online Store Name",
    type: "input",
    title: "Online Store Name",
  },
  {
    id: "isActive",
    label: "Is Active",
    type: "checkBox",
    title: "Is Active",
  },
  {
    id: "onlineStoreDescription",
    placeholder: "Online Store Description",
    type: "textarea",
    title: "Online Store Description",
    class: "grid-row-two-column",
  },
  {
    id: "imageNormal",
    placeholder: "Upload Image",
    type: "fileUploader",
    title: "Product Image",
  },
  {
    id: "imageNormal.secure_url",
    type: "input",
    placeholder: "Image URL",
    title: "Image URL",
  },
  {
    id: "notes",
    placeholder: "Notes",
    type: "textarea",
    title: "Notes",
    class: "grid-row-two-column",
  },
];

export const extraInfoInputs = [
  {
    id: "name",
    placeholder: "Name",
    type: "select",
    title: "Name",
    options: productExtraInfo,
  },
  {
    id: "value",
    type: "input",
    title: "Value",
    placeholder: "Value",
  },
];

export const defaultSelectedMsoProduct = {
  _id: "NEW",
  batchId: "",
  name: "",
  brand: undefined,
  isDeleted: false,
  isArchived: false,
  isActive: true,
  notes: "",
  cannabisGramsUnit: "",
  onlineStoreDescription: "",
  onlineStoreName: "",
  brandName: "",
  verifiedBy: {},
  createdBy: {},
  updatedBy: {},
  imageNormal: {},
  imageThumbnail: {},
  msoGroup: undefined,
  msoSupplier: undefined,
  flavors: [],
  effects: [],
  terpenes: [],
  tags: [],
};
