<template>
  <div class="input-wrapper reversed-flex-column" :style="customStyle">
    <!-- Validation -->
    <p
      class="input-wrapper__validation-message"
      v-if="isEdit && ((validation && validation.isInvalid) || validateField)"
    >
      {{ validateField || validation.message }}
    </p>
    <!-- Edit Mode -->
    <template
      v-if="
        (isEdit && !hide && type !== 'button' && type !== 'link') ||
        (!hide && type == 'search-btn')
      "
    >
      <v-select
        :data-cy="generateTestMetadata()"
        v-if="type === 'select'"
        v-model="modelVal"
        class="input-text__select"
        :placeholder="placeholder"
        :options="options"
        :label="label"
        @input="$emit('input')"
        :disabled="dynamicDisable || disabled"
        :clearable="inputData.isClearable"
      />
      <TheMask
        v-else-if="type === 'mask'"
        :placeholder="placeholder || title"
        :masked="true"
        v-model="modelVal"
        class="input-text__field"
        :mask="mask"
        :disabled="dynamicDisable"
        :data-cy="generateTestMetadata()"
      />
      <template v-else-if="type === 'checkBox' || type === 'checkbox'">
        <CheckBox
          :isRadio="disabled || dynamicDisable"
          :data-cy="generateTestMetadata()"
          :toggleVariable="modelVal"
          :id="id"
          @click="
            () => {
              if (!disabled && !dynamicDisable) modelVal = !modelVal;
            }
          "
        />
      </template>
      <template v-else-if="type === 'fileUploader'">
        <div class="two-columns-layout two-columns-layout--responsive">
          <div class="input-text">
            <div class="reversed-flex-column">
              <FileUploader
                :data-cy="generateTestMetadata()"
                iconGroup="fas"
                iconName="upload"
                allowedTypes="image/png, image/jpeg"
                @change="updateImage"
                @remove-image="() => removeImage()"
                :disabled="!isEdit"
                :image="modelVal.data || modelVal.secure_url"
                :id="id"
              />
            </div>
          </div>
        </div>
      </template>
      <vue-numeric
        v-else-if="
          type === 'money' || type === 'number' || type === 'percentage'
        "
        :data-cy="generateTestMetadata()"
        :currency="prefix ? prefix : suffix"
        separator=","
        :precision="
          precision === undefined
            ? Constants.numericInput.moneyPrecision
            : precision
        "
        :currency-symbol-position="prefix ? 'prefix' : 'suffix'"
        class="input-text__field"
        v-model="modelVal"
        :disabled="disabled || dynamicDisable"
      ></vue-numeric>
      <input
        :data-cy="generateTestMetadata()"
        v-else-if="type === 'input' || type == 'srn'"
        class="input-text__field"
        v-model="modelVal"
        :placeholder="placeholder || title"
        :type="inputType"
        :ref="inputData.focus ? 'inputToFocus' : null"
        :disabled="dynamicDisable || disabled === true"
      />
      <textarea
        :data-cy="generateTestMetadata()"
        v-else-if="type === 'textarea'"
        class="input-text__textarea"
        v-model="modelVal"
        rows="4"
        :placeholder="placeholder"
        style="max-width: none"
        :disabled="dynamicDisable || disabled === true"
      />
      <div class="calendar-wrapper" v-else-if="type === 'calendar'">
        <Calendar
          :data-cy="generateTestMetadata()"
          v-model="modelVal"
          :placeholder="placeholder"
          :disabled="dynamicDisable"
          :showTime="inputData.showTime"
          :monthNavigator="true"
          :yearNavigator="true"
          hourFormat="12"
          :yearRange="dateRange"
          hideOnDateTimeSelect=true
        />
        <BaseBtn
          :colorType="1"
          class="calendar-wrapper__clear"
          iconGroup="fas"
          iconName="times"
          @click="() => (modelVal = '')"
        />
      </div>
      <div class="calendar-wrapper" v-else-if="type === 'calendar-dob'">
        <Calendar
          :data-cy="generateTestMetadata()"
          v-model="modelVal"
          :placeholder="placeholder"
          :disabled="dynamicDisable"
          :showTime="inputData.showTime"
          :monthNavigator="true"
          :yearNavigator="true"
          hourFormat="12"
          yearRange="1910:2030"
        />
        <BaseBtn
          :colorType="1"
          class="calendar-wrapper__clear"
          iconGroup="fas"
          iconName="times"
          @click="() => (modelVal = '')"
        />
      </div>
      <template v-else-if="type === 'search'">
        <div class="field__content input__search-box">
          <template>
            <div v-if="fieldsToDisplay">
              <div v-for="field in fieldsToDisplay" :key="field.id">
                <span class="input__label">{{ field.label }}:</span>
                <span>{{ model ? model[field.id] : "-" }}</span>
              </div>
            </div>
            <div v-else-if="!buttonOnly">
              {{
                getFormattedValue(
                  model !== undefined && model !== null ? model : value,
                  type,
                  prefix,
                  suffix
                )
              }}
            </div>
            <div class="search-button-wrapper">
              <BaseBtn
                :data-cy="generateTestMetadata('search-button')"
                @click="toggleSearchModal"
                iconGroup="fas"
                iconName="search"
                class="search-button"
              />
              <BaseBtn
                v-if="
                  isClearable &&
                  modelVal &&
                  JSON.stringify(modelVal) != JSON.stringify({})
                "
                :colorType="1"
                class="search-button search-button--right"
                iconGroup="fas"
                iconName="times"
                @click="clearSearch"
              />
            </div>
          </template>
        </div>
        <SearchModal
          v-if="showSearchModal"
          :showSearchModal="showSearchModal"
          @toggle-modal="toggleSearchModal"
          @select-item="selectItem"
          :entity="entity"
          :id="id"
          :title="title"
          :nestedId="inputData.nestedId"
          :forcedFilterCriteria="filterCriteria"
          :columnHeaders="columnHeaders"
          :parentEntity="inputData.parentEntity"
          :isFiltersDisabled="isFiltersDisabled"
          :defaultFilter="inputData.defaultFilter"
          :additionalFilters="inputData.additionalFilters"
          :filter="inputData.filter"
          :sort="inputData.sort"
          :forcedItems="forcedItems"
          :hideFilter="inputData.hideFilter"
          :filterMetadata="filterMetadata"
          :initialFilterValue="initialFilterValue"
        />
      </template>
      <template v-else-if="type === 'search-btn'">
        <div class="field__content">
          <template>
            <BaseBtn
              :data-cy="generateTestMetadata('search-button')"
              @click="toggleSearchModal"
              iconGroup="fas"
              iconName="plus"
              label="Add"
              :disabled="!isEdit"
              class="search-button--right"
            />
          </template>
        </div>
        <SearchModal
          v-if="showSearchModal"
          :showSearchModal="showSearchModal"
          @toggle-modal="toggleSearchModal"
          @select-item="selectItem"
          :entity="entity"
          :id="id"
          :title="title"
          :nestedId="inputData.nestedId"
          :forcedFilterCriteria="filterCriteria"
          :columnHeaders="columnHeaders"
          :parentEntity="inputData.parentEntity"
          :isFiltersDisabled="isFiltersDisabled"
          :defaultFilter="inputData.defaultFilter"
          :additionalFilters="inputData.additionalFilters"
          :filter="inputData.filter"
          :sort="inputData.sort"
          :forcedItems="forcedItems"
          :filterMetadata="filterMetadata"
        />
      </template>
      <div v-else class="field__content">
        {{
          getFormattedValue(
            model !== undefined && model !== null ? model : value,
            type,
            prefix,
            suffix
          )
        }}
      </div>
    </template>
    <!-- Read only mode -->
    <template v-else-if="type === 'link' || type == 'button'">
      <div v-if="type === 'link'" class="field__content">
        <router-link
          class="internal-link icon-margin-left"
          target="_blank"
          :to="{
            name: to.name,
            query: { id: lodash.get(model, to.valueKey) },
          }"
        >
          <span v-if="to.valueName" class="link-text">{{
            lodash.get(model, to.valueName)
          }}</span>
          <span v-else class="link-text">{{
            lodash.get(model, to.valueKey)
          }}</span>
          <blank-link-icon :hasLeftMargin="true" />
        </router-link>
      </div>
      <BaseBtn
        :data-cy="generateTestMetadata()"
        v-else-if="type === 'button'"
        :label="title"
        class="full-width"
        :disabled="!isEdit || dynamicDisable"
        @click="$emit('on-click')"
      />
    </template>

    <template v-else-if="type === 'linkNoIcon'">
      <div v-if="type === 'linkNoIcon'" class="field__content">
        <router-link
          class="internal-link"
          target="_blank"
          :to="{
            name: to.name,
            query: { id: lodash.get(selectedItem, to.valueKey) },
          }"
        >
          <div class="link-text">
            <span>{{ lodash.get(selectedItem, to.valueKey) }}</span>
          </div>
        </router-link>
      </div>
    </template>

    <div v-else-if="type === 'checkBox' || type === 'checkbox'">
      <CheckBox
        :isRadio="disabled || dynamicDisable"
        :toggleVariable="modelVal"
      />
    </div>
    <template v-else-if="type === 'fileUploader'">
      <div class="two-columns-layout two-columns-layout--responsive">
        <div class="input-text">
          <div class="reversed-flex-column">
            <FileUploader
              :data-cy="generateTestMetadata()"
              iconGroup="fas"
              iconName="upload"
              allowedTypes="image/png, image/jpeg"
              @change="updateImage"
              @remove-image="() => removeImage()"
              :disabled="!isEdit"
              :image="modelVal.data || modelVal.secure_url"
              :id="id"
            />
          </div>
        </div>
      </div>
    </template>
    <div
      v-else
      :class="
        (type === 'text' || disabled === true || fieldsToDisplay
          ? 'field__content'
          : 'input-text__field input-text__disabled') +
        (type === 'textarea' ? ' grid-notes' : '')
      "
    >
      <div v-if="fieldsToDisplay">
        <div
          class="fields-to-display"
          v-for="field in fieldsToDisplay"
          :key="field.id"
        >
          <span class="input__label">{{ field.label }}:</span>
          <router-link
            v-if="field.type === 'link'"
            class="internal-link"
            target="_blank"
            :to="{
              name: field.to.name,
              query: { id: lodash.get(model, field.to.valueKey) },
            }"
          >
            <span>{{ lodash.get(model, field.id) }}</span>
          </router-link>
          <span v-else>{{ model ? lodash.get(model, field.id) : "-" }}</span>
        </div>
      </div>
      <div v-else>
        {{
          hide
            ? "-"
            : getFormattedValue(
                model !== undefined && model !== null ? model : value,
                type,
                prefix,
                suffix
              )
        }}
      </div>
    </div>
    <div v-if="!buttonOnly" class="input-text__label">
      {{ title }}{{ required ? " *" : "" }}
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Constants from "@/resources/Constants";
import Utilities from "@/lib/Utilities";
import CheckBox from "@/components/CheckBox.vue";
import FileUploader from "@/components/FileUploader/FileUploader.vue";
import Calendar from "primevue/calendar";
import { TheMask } from "vue-the-mask";
import VueNumeric from "vue-numeric";
import _ from "lodash";
import BaseBtn from "@/components/Base/Button/Button.vue";
import { parseSrn } from "@/lib/srnHelper";

export default {
  name: "Input",
  props: {
    model: {
      type: [String, Boolean, Number, Object, Date],
    },
    isEdit: {
      type: Boolean,
    },
    customStyle: {
      type: String,
    },
    selectedItem: {
      type: Object,
    },
    validation: {
      type: [Object, Boolean],
    },
    inputData: {
      type: Object,
      default: function () {
        return {
          placeholder: "",
          type: "text",
          title: "",
          disabled: false,
          mask: undefined,
          label: undefined,
          prefix: undefined,
          suffix: undefined,
          precision: undefined,
          options: [],
          hide: false,
          inputType: undefined,
          checkboxLabel: undefined,
          to: undefined,
          show: undefined,
          value: undefined,
          isFiltersDisabled: false,
          fieldsToDisplay: undefined,
          isClearable: false,
          showTime: false,
        };
      },
    },
    entityObject: {
      type: Object,
      default: function () {
        return {};
      },
    },
    forcedItems: {
      type: Array,
      default: undefined,
    },
    initialFilterValue: {
      type: String,
    },
  },
  data() {
    return {
      Constants: Constants,
      moment: moment,
      Utilities: Utilities,
      placeholder: this.inputData.placeholder,
      type: this.inputData.type,
      title: this.inputData.title,
      disabled: this.inputData.disabled,
      mask: this.inputData.mask,
      label: this.inputData.label,
      prefix: this.inputData.prefix,
      suffix: this.inputData.suffix,
      precision: this.inputData.precision,
      options: this.inputData.options,
      hide: this.inputData.hide,
      inputType: this.inputData.inputType,
      checkboxLabel: this.inputData.checkboxLabel,
      to: this.inputData.to,
      show: this.inputData.show,
      value: this.inputData.value,
      showSearchModal: false,
      filterCriteria: this.inputData.filterCriteria,
      columnHeaders: this.inputData.columnHeaders,
      entity: this.inputData.entity,
      id: this.inputData.id,
      required: this.inputData.required,
      buttonOnly: this.inputData.buttonOnly,
      isFiltersDisabled: this.inputData.isFiltersDisabled,
      fieldsToDisplay: this.inputData.fieldsToDisplay,
      isClearable: this.inputData.isClearable || false,
      lodash: _,
      dateFormat: this.inputData.showTime
        ? Constants.dates.extendedCalendarDateFormat
        : Constants.dates.defaultDateFormat,
      filterMetadata: this.inputData.filterMetadata,
    };
  },
  components: {
    TheMask,
    Calendar,
    CheckBox,
    FileUploader,
    VueNumeric,
    BaseBtn,
  },
  mounted: function () {
    this.$nextTick(() => {
      this.$refs?.inputToFocus?.focus();
    });
  },
  watch: {
    isEdit() {
      if (this.type === "calendar")
        this.modelVal = this.getFormattedValue(this.modelVal, this.type);
    },
  },
  computed: {
    dynamicDisable() {
      if (this.inputData.disableFunction) {
        return this.inputData.disableFunction(this.entityObject);
      } else {
        return false;
      }
    },
    dateRange() {
      const currentDate = new Date();
      const fromDate = new Date();
      const toDate = new Date();

      fromDate.setFullYear(currentDate.getFullYear() - 5);
      toDate.setFullYear(currentDate.getFullYear() + 5);

      const fromYear = fromDate.getFullYear();
      const toYear = toDate.getFullYear();

      return `${fromYear}:${toYear}`;
    },
    modelVal: {
      get() {
        return this.formatInput(this.model);
      },
      set(val) {
        const result = this.formatResult(val);
        this.$emit("updateModel", result);
      },
    },
    validateField() {
      if (
        this.modelVal &&
        this.type === "calendar" &&
        !moment(this.modelVal).isValid()
      )
        return "Date is invalid";
      else return undefined;
    },
    fileUploaderStyle() {
      return this.modelVal
        ? `background-image: url('${
            this.modelVal.data || this.modelVal.secure_url
          }'`
        : "";
    },
  },
  methods: {
    generateTestMetadata(inputPrefix) {
      let prefix = "input";
      if (inputPrefix) {
        prefix = inputPrefix;
      }
      return `${prefix}-${this.inputData.id}`;
    },
    getFormattedValue(value, type, prefix, suffix) {
      if (
        value === undefined ||
        value === null ||
        (!value && value != 0) ||
        (value == "-" && type !== "calendar")
      ) {
        if (type == "calendar") {
          return null;
        }
        return "-";
      }
      if (this.inputType == "password") {
        return "*********";
      }
      if (type === "checkBox") return value ? "Yes" : "No";
      if (type === "weight")
        return `${new Intl.NumberFormat("en-US", {
          maximumSignificantDigits: 5,
        }).format(value || 0)}g`;
      if (type === "number") {
        return value;
      }
      if (type === "percentage") {
        return `${value} %`;
      }
      if (type === "money")
        return `${new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(value || 0)}${suffix || ""}`;
      if (type === "date" || type === "calendar")
        if (!value) return "";
        else return moment(value).format(this.dateFormat);
      if (type === "select") {
        if (typeof value === "object") return value.label;
        else if (typeof this.options[0] === "object")
          return this.options.find((o) => o.code === value)?.label;
        else return value;
      }
      if (type === "search" && typeof value === "object") {
        return value.label || value.name || value._id || "-";
      }

      if (type === "name") return Utilities.composeNameAndLastName(value);
      if (type === "text" && prefix) return `${prefix}${value}`;
      if (type === "text" && suffix) return `${value}${suffix}`;
      else return value;
    },
    clearSearch() {
      this.$parent.clearItemFromSearch(this.entity);
    },
    toggleSearchModal: function () {
      this.showSearchModal = !this.showSearchModal;
    },
    selectItem(entity, item, nestedId) {
      this.$parent.selectItemFromSearch(entity, item, nestedId);
    },
    updateImage(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.createImage(files[0]);
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = (e) => {
        this.modelVal = { data: e.target.result };
        this.$forceUpdate();
      };
      reader.readAsDataURL(file);
    },
    removeImage: function () {
      this.modelVal = {};
      this.$forceUpdate();
    },
    formatInput(value) {
      if (this.type === "select") {
        if (typeof this.options[0] === "object" && typeof value !== "object") {
          return this.options.find((o) => o.code === value);
        }
      }
      if (this.type == "calendar") {
        if (!value) return value;
        return new Date(value);
      }
      return value;
    },
    formatResult(value) {
      if (this.type === "select") {
        if (typeof value === "object") {
          return value?.code;
        }
      }

      if (this.type === "srn") {
        return parseSrn(value);
      }
      return value;
    },
  },
};
</script>

<style scoped lang="scss">
@import "./_input.scss";

.link-text {
  margin-right: 10px;
}

.image-preview {
  width: 280px;
  border-radius: 2px;
}

.default-image-preview {
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $dark-elevated-0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.light .default-image-preview {
  background-color: $light-elevated-0;
}
</style>
