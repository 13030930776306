<template>
  <div>
    <a style="padding: 1rem 1.125rem" :href="siteUrl" target="_blank" rel="noopener noreferrer">Visit</a>
    <div v-if="!finishedLoadingPreview" style="width: 100%; text-align: center;">
      <i class="fas fa-spin fa-circle-notch"></i>
    </div>
    <iframe ref="iframe" :src="siteUrl" width="100%" height="700px"
      style="border: none; background: white;  overflow: hidden;" sandbox="allow-scripts allow-same-origin"></iframe>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "viewOnlineStore",
  data() {
    return {
      finishedLoadingPreview: false,
    };
  },
  computed: {
    ...mapGetters({
      store: "storeModule/currentStore",
    }),
    siteUrl: function () {
      return this.store.siteUrl;
      //return "http://localhost:3000"
    },
  },
  mounted: function () {
    if (this.$refs.iframe) {
      this.$refs.iframe.addEventListener('load', this.onIframeLoad);
    }
  },
  beforeDestroy() {
    this.$refs?.iframe?.removeEventListener('load', this.onIframeLoad);
  },
  methods: {
    onIframeLoad() {
      this.finishedLoadingPreview = true;
    },
  }
}
</script>
